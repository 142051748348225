
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Header',
  props: {
    msg: {
      type: String,
      required: false,
    },
  },
  methods:{
    updateCongress(congress:string){
      console.log('updateCongress', congress)
    }
  }
});
