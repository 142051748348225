import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Home from '../views/Home.vue'
import 'vue-router'
import trials from '@/data/trials.json'

declare module 'vue-router' {
  interface RouteMeta {
    // must be declared by every route
    title: string
    gtm?: string
  }
}
const siteName = 'hcuconnection.com';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: {name: 'Home'}
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title:`Home`
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title:`About`
    },
  }, 
  {
    path: `/how-to-participate`,
    name: `HowToParticipate`,
    component: () => import(/* webpackChunkName: "Choose" */ `../views/HowToParticipate.vue`),
    meta: {
      title:`How to Participate`
    },
  },
  {
    path: `/trials/:trialId`,
    name: `TrialId`,
    component: () => import(/* webpackChunkName: "Choose" */ `../views/TrialContainer.vue`),
    props:true,
    meta: {
      title:`Study`
    },
    // component: BrandInfo
  },
  
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return desired position
    if (to.hash) {
      return {
        el: to.hash,
      }
    }else{
      return { top: 0 }
    }
  }
  
})



// redirect to login page if not logged in and trying to access a restricted page
router.beforeEach((to, from, next) => {

  const metaTitle = <string>to.meta.title

  if (metaTitle) {
    let gtm = ''
    if(to.params.trialId){
      const trialName = trials.find(t=>t.id==to.params.trialId)?.title || 'Trial'
      document.title = `${trialName} ${metaTitle} - ${siteName}`
      gtm = trialName + ' ' +  metaTitle
    }else{
      document.title = `${metaTitle} - ${siteName}`
      gtm =  metaTitle
    }
    to.meta.gtm = gtm
  }

  

  next();
})

export default router
