import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")
  const _component_router_view = _resolveComponent("router-view")
  const _component_Footer = _resolveComponent("Footer")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createVNode("main", null, [
      _createVNode(_component_router_view, {
        key: _ctx.$route.fullPath
      })
    ]),
    _createVNode(_component_Footer, { ref: "footer" }, null, 512)
  ], 64))
}