
// @ is an alias to /src
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
// import TrackMetrics from "./directives/TrackMetrics";
import { defineComponent } from 'vue';

export default defineComponent({
 name: 'App',
  components: {
    Header, Footer
  },
  data:function() {
    return {
      timeout:null,
      noScrollClass:null
    };
  },
  directives: {
      // TrackMetrics
  },
  computed: {
  },
  methods:{
   
  
    enableGA(){
      console.log('enableGA')
      // console.log('ga', this.$ga)
      // this.$ga.enable()
    },
    disableGA(){
      console.log('disableGA')
      // console.log('ga', this.$ga)
      // this.$ga.disable()
    }
    


  },
  
  

});
