
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Footer',
  setup(){
    const showScrollTop = ref(false)
    return {
      showScrollTop
    }
  },
  computed: {
    //  currentCongress () {
    //   return this.$store.state.congress;
    // },

    currentYear(){
      return new Date().getFullYear();
    }
  },
   methods:{
    // checkCongressName(congress){
    //   return !!this.currentCongress == congress;
    // }
    handleScroll(e:any) {
      var scrollEl =  (e.target as Document).scrollingElement;
      if(scrollEl){
        this.showScrollTop = ( scrollEl.scrollTop > 100)
      }
    },



  },

      mounted() {
     document.addEventListener('scroll', this.handleScroll)
  },
  unmounted() {
    console.log("unmounted")
    document.removeEventListener('scroll', this.handleScroll)
  }

});
