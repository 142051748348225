
import { defineComponent } from 'vue';
import  CarouselComponent  from './../components/CarouselComponent.vue';
// import  ArticleSection  from './../components/ArticleSection.vue';

export default defineComponent({
  name: 'Home',
  components: {
    CarouselComponent,
    // ArticleSection
  },
});
